._3cxeOzQec8zqStfZCXZWfG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

._3l7TrIa5AKEks_HCGGD6q5 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

._3l7TrIa5AKEks_HCGGD6q5:not(:first-of-type) {
  padding-left: 16px;
  padding-left: 1rem;
}

._1JN3aEKlPD7X5jqB1dXYzx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

._1JN3aEKlPD7X5jqB1dXYzx > div {
  position: relative;
  width: 100%;
}

._1JN3aEKlPD7X5jqB1dXYzx input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100% !important;
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 1px 10px 0;
  height: 40px;
}

._1JN3aEKlPD7X5jqB1dXYzx input:focus {
  outline: auto 5px -webkit-focus-ring-color;
}

._1JN3aEKlPD7X5jqB1dXYzx label {
  font-weight: normal;
  line-height: 40px;
  padding-right: 8px;
  position: absolute;
  right: 24px;
  right: 1.5rem;
  text-align: right;
  text-transform: lowercase;
}

._2dv0LNfUzjAAPh-wPACjXb {
  background-color: white;
  line-height: 40px;
  padding: 1px 10px 0;
}

.wPHh9nW_2p42Vk16HDf2n {
  background-color: #42526e;
  color: #fff;
}

